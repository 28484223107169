export enum LayoutType { vertical = "vertical", auth = "auth", fullpage = "fullpage", auth2 = 'auth2', company = 'company' };
export enum LayoutWidth { fluid = "fluid", boxed = "boxed" };
export enum SidebarSize { smHover = "sm-hover", smHoverActive = "sm-hover-active" };
export enum SidebarColor { light = "light", dark = "dark" };
export enum SidebarImage { none = "none" };
export enum SidebarView { detached = "detached", default = "default" };
export enum Topbar { light = "light", dark = "dark" };
export enum Mode { light = "light", dark = "dark" };
export enum Postion { fixed = "fixed" };
export enum Preloader { enable = "enable" };
export enum FooterType { wide = "wide", centered = "centered" };
export enum FilterType { text = "text", number = "number", dropdown = "dropdown", picklist = "picklist", date = "date"};